
import dayjs from 'dayjs'
import { timesFormat } from '@/utils/index'
import { URLS } from '@/common/js/constant/index'
import TextView from './components/textView.vue'
import HomeTable from './components/homeTable.vue'
import KlookIcon from '@/components/icon.vue'
import { FieldTimeOutlined } from '@ant-design/icons-vue'

interface PaginationTS {
  total: number
  current: number
  pageSize: number
}
interface ParamsTS {
  limit: number
  page: number
  category_id?: string | undefined
  booking_no?: string
  version?: string
  from?: string
  to?: string
  isExport?: number
}
interface exportParamsTS {
  content: string
  duration: number
  type: 'success' | 'error' | 'loading'
}

import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { get } from '@/plugins'
import { message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import { get_export } from '@/plugins/axios'
const key = 'updatable'

export default defineComponent({
  components: {
    TextView,
    HomeTable,
    KlookIcon,
    FieldTimeOutlined
  },
  setup() {
    const { t } = useI18n({})
    const lastYear = dayjs().subtract(1, 'year').startOf('month')
    const state: {
      form: {
        time: any[]
        booking_no: string
        category_id: string[] | undefined
      }
      data: {
        time: any[]
        searchLoading: boolean
        resetLoading: boolean
        visible: boolean
        currentTime: string
        selectVersion: string
        visibleTopAlert: boolean
        selectVersionDay: string
      }
      pagination: PaginationTS
      list: any
      res: any
      historiyList: any
      cache: any[]
      exportObj: {
        loading: Boolean
        TaskNo: string
        name: string
      }
      httpParams: any
      categoryList: any[]
    } = reactive({
      form: {
        time: [dayjs().subtract(1, 'day').startOf('month'), dayjs().subtract(1, 'day')],
        booking_no: '',
        category_id: undefined
      },
      data: {
        time: [dayjs().subtract(1, 'day').startOf('month'), dayjs().subtract(1, 'day')],
        searchLoading: false,
        resetLoading: false,
        visible: false,
        currentTime: '',
        selectVersion: '',
        visibleTopAlert: false,
        selectVersionDay: ''
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      list: [], // table列表数据
      res: {}, // 统计数据
      historiyList: [], // 统计数据
      cache: [dayjs().subtract(1, 'day').startOf('month'), dayjs().subtract(1, 'day')],
      exportObj: {
        loading: false,
        TaskNo: '',
        name: ''
      },
      httpParams: {}, // 获取接口时参数缓存
      categoryList: []
      // typeKey:0,
      // typeList: [
      //   { value: "月", key: 0 },
      //   { value: "天", key: 1 },
      // ];
    })
    // @Watch("typeKey")
    //  typeKeyChanged(typeKey: number) {
    // }
    onMounted(() => {
      console.log(process.env.NODE_ENV)
      const params: ParamsTS = {
        page: state.pagination.current,
        limit: state.pagination.pageSize
      }
      if (state.form.time.length > 0) {
        params.from = state.form.time[0].startOf('day').format(timesFormat)
        params.to = state.form.time[1].endOf('day').format(timesFormat)
      }
      getCategoryList()
      getListFunc(params)
      getCountTotalFunc(params)
      getHistoryVersionFunc()
    })

    // 禁止选择
    const disabledDate = (times: any) => {
      if (state.data.visibleTopAlert) {
        // 历史版本
        // 获取选中历史版本前一年时间
        let startTime = dayjs(state.data.selectVersionDay).subtract(1, 'year').add(1, 'month').startOf('month').valueOf()
        const deadTiem = dayjs(state.data.selectVersionDay).endOf('month').valueOf()
        if (startTime < lastYear.subtract(2, 'months').valueOf()) {
          // 如果获取的选中历史版本前一年的开始时间小于默认的时间范围， 取默认时间
          startTime = lastYear.subtract(2, 'months').valueOf()
        }

        if (dayjs(times).valueOf() > deadTiem) {
          // 当前时间以及将来不能选择
          return true
        } else if (dayjs(times).valueOf() < startTime) {
          // 不能小于当前时间的一年前月初
          return true
        }
      } else {
        // 默认版本
        if (dayjs(times).valueOf() > dayjs().subtract(1, 'day').valueOf()) {
          // 当前时间以及将来不能选择 dayjs().subtract(1, 'year').startOf('month')
          return true
        } else if (dayjs(times).valueOf() < lastYear.subtract(2, 'months').valueOf()) {
          // 不能小于当前时间的一年前月初
          return true
        }
      }
      // else if (dayjs(times).valueOf() > dayjs(state.cache[0]).add(1, 'year').valueOf()) {
      //   return true
      // }
      return false
    }

    const onChange = (page: number, size: number) => {
      const params: ParamsTS = {
        page: page > 0 ? page : 1,
        limit: size || state.pagination.pageSize
      }
      if (state.form.time.length > 0) {
        params.from = state.form.time[0].startOf('day').format(timesFormat)
        params.to = state.form.time[1].endOf('day').format(timesFormat)
      }
      state.form.booking_no ? (params.booking_no = state.form.booking_no.trim().replace(/\s+/gi, ',')) : null
      params.category_id = state.form.category_id?.join(',')
      getListFunc(params)
      getCountTotalFunc(params)
    }

    // 缓存筛选时间，方便用户选择监控
    const cacheChange = (times: any) => {
      state.cache = times
    }
    // 缓存筛选时间，重置
    const openChange = (flag: boolean) => {
      if (flag) {
        state.cache = state.form.time
      }
    }

    // 获取类别列表
    const getCategoryList = () => {
      get(URLS.getCategoryList).then((res: any) => {
        state.categoryList = res.category_list || []
      })
    }
    // 列表List统计
    const getListFunc = (params: ParamsTS) => {
      if (state.data.visibleTopAlert) {
        params.version = state.data.selectVersionDay
      } else {
        params.version = state.data.currentTime
      }

      get(URLS.getEscrowList, params).then((res: any) => {
        state.pagination.total = res.total
        state.pagination.pageSize = params.limit
        state.pagination.current = params.page
        state.list = res.escrow_item_list || []
        state.httpParams = params
      })
    }

    //  列表总数统计
    const getCountTotalFunc = (params: ParamsTS) => {
      if (state.data.visibleTopAlert) {
        params.version = state.data.selectVersionDay
      } else {
        params.version = state.data.currentTime
      }
      get(URLS.getEscrowCount, params)
        .then((res: any) => {
          state.res = res
        })
        .finally(() => {
          state.data.searchLoading = false
          state.data.resetLoading = false
        })
    }

    //  获取历史版本
    const getHistoryVersionFunc = () => {
      get(URLS.getEscrowHistoryVersion)
        .then((res: any) => {
          const versions = (res && res.versions) || []
          const currentVersion = versions.filter((item: { default: any }) => item.default)
          state.historiyList = versions.filter((item: { default: any }) => !item.default)
          state.data.currentTime = (currentVersion && currentVersion.length > 0 && currentVersion[0].date) || ''
        })
        .finally(() => {
          state.data.searchLoading = false
          state.data.resetLoading = false
        })
    }

    // 重设功能
    const onReset = () => {
      const params: ParamsTS = {
        page: 1,
        limit: 10
      }

      if (state.data.visibleTopAlert) {
        let startDataTime = dayjs(state.data.selectVersionDay).subtract(1, 'year').add(1, 'month').startOf('month')
        if (startDataTime.valueOf() < lastYear.subtract(2, 'months').valueOf()) {
          startDataTime = lastYear.subtract(2, 'months')
        }

        const times = [startDataTime, dayjs(state.data.selectVersionDay).endOf('month')]
        state.form.time = times
        state.cache = times // 重置
        if (state.form.time.length > 0) {
          params.from = state.form.time[0].startOf('day').format(timesFormat)
          params.to = state.form.time[1].endOf('day').format(timesFormat)
        }
      } else {
        const times = [dayjs().subtract(1, 'day').startOf('month'), dayjs().subtract(1, 'day')]
        state.form.time = times // 重置
        state.cache = times // 重置
        if (state.form.time.length > 0) {
          params.from = times[0].startOf('day').format(timesFormat)
          params.to = state.form.time[1].endOf('day').format(timesFormat)
        }
      }

      state.form.booking_no = ''
      state.form.category_id = undefined

      getListFunc(params)
      getCountTotalFunc(params)
      state.data.resetLoading = true
    }

    // 搜索功能
    const onSearch = () => {
      const params: ParamsTS = {
        page: 1,
        limit: 10
      }
      if (state.form.time.length > 0) {
        params.from = state.form.time[0].startOf('day').format(timesFormat)
        params.to = state.form.time[1].endOf('day').format(timesFormat)
      }
      state.form.booking_no ? (params.booking_no = state.form.booking_no.trim().replace(/\s+/gi, ',')) : null
      params.category_id = state.form.category_id?.join(',')
      getListFunc(params)
      getCountTotalFunc(params)
      state.data.searchLoading = true
    }

    // 查看历史版本
    const viewHistoricalVersion = () => {
      state.data.visible = true
    }

    // 选中历史版本
    const didSelectHistoricalVersion = () => {
      state.data.visible = false
      if (state.data.selectVersion) {
        state.data.visibleTopAlert = true
        const versionObj = state.historiyList.find((item: { version: string }) => item.version == state.data.selectVersion)
        state.data.selectVersionDay = versionObj.date

        // 设置时间
        let startDataTime = dayjs(versionObj.date).subtract(1, 'year').add(1, 'month').startOf('month')
        if (startDataTime.valueOf() < lastYear.subtract(2, 'months').valueOf()) {
          startDataTime = lastYear.subtract(2, 'months')
        }

        state.form.time = [startDataTime, dayjs(versionObj.date).endOf('month')]

        onSearch()
      }
    }

    const versionFilter = (inputValue: string, path: any) => {
      return path.some((option: { label: string }) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }

    // 切换到默认版本
    const switchDefaultVersion = () => {
      state.data.visibleTopAlert = false
      onReset()
    }

    // 选中历史版本
    const changeHistoricalVersion = (value: string) => {
      state.data.selectVersion = value
    }

    // 导出功能
    const exportFunc = () => {
      state.exportObj.loading = true
      const params: any = {}
      if (state.httpParams && state.httpParams.from && state.httpParams.to) {
        params.from = state.httpParams.from
        params.to = state.httpParams.to
      }

      if (state.data.visibleTopAlert) {
        params.version = state.data.selectVersionDay
      } else {
        params.version = state.data.currentTime
      }
      state.form.booking_no ? (params.booking_no = state.form.booking_no.trim().replace(/\s+/gi, ',')) : null
      params.category_id = state.form.category_id?.join(',')

      exportView({ content: t('76179'), duration: 0, type: 'loading' })
      get(URLS.summitExport, params)
        .then((res: any) => {
          if (res.task_no) {
            state.exportObj.TaskNo = res.task_no
            state.exportObj.name = res.file_name
            checkExportFunc() // 执行心跳维持
          }
        })
        .catch(() => {
          state.exportObj.loading = false
          state.exportObj.TaskNo = ''
          state.exportObj.name = ''
        })
    }

    // 导出message显示
    const exportView = (params: exportParamsTS) => {
      message[params.type]({
        content: params.content,
        duration: params.duration,
        key,
        class: 'export-class'
      }).then(() => {
        const TaskNo = state.exportObj.TaskNo
        if (params.type === 'success') {
          exportDownload(TaskNo)
        }
        if (params.type === 'error') {
          state.exportObj.loading = false
          state.exportObj.TaskNo = ''
          state.exportObj.name = ''
        }
      })
    }

    // 导出成功，执行下载
    const exportDownload = (TaskNo: string) => {
      get_export(URLS.downloadExport, {
        task_no: TaskNo
      })
        .then((res: any) => {
          // get成功后，res后端返回的数据
          let content = new Blob([res.data], {})
          //兼容性处理
          let urlObject = window.URL || window.webkitURL || window
          //获取下载地址href的指向
          let url = urlObject.createObjectURL(content)
          //创建a元素
          let a = document.createElement('a')
          //给a元素的href添加下载后端传递过来的文件
          a.href = url
          //给下载的文件添加名字，下面是我写死的，如果后端给的每一个文件名都不一样，可以通过res获取文件名，然后给数据
          a.download = state.exportObj.name || `klook-export.xlsx`
          //给生成的a元素绑定单击事件
          a.click()
          // 静态方法用来释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
          urlObject.revokeObjectURL(url)
        })
        .finally(() => {
          state.exportObj.loading = false
          state.exportObj.TaskNo = ''
          state.exportObj.name = ''
        })
    }

    // 导出 心跳维持
    const checkExportFunc = () => {
      get(URLS.checkExport, {
        task_no: state.exportObj.TaskNo
      })
        .then((res: any) => {
          if (res.Status) {
            setTimeout(() => {
              checkExportStatus(res.Status, res)
            }, 5000)
          }
        })
        .catch(() => {
          checkExportStatus(3, {})
        })
    }

    // 递归检测当前导出状态
    const checkExportStatus = (status: number, res?: any) => {
      // Status 1 是 process (处理中) 2 是 success (成功) 3 是 failed (失败)
      switch (status) {
        case 1:
          checkExportFunc()
          break
        case 2:
          state.exportObj.name = res.FileName
          exportView({ content: t('76180'), duration: 3, type: 'success' })
          break
        case 3:
          exportView({ content: t('76181'), duration: 3, type: 'error' })
          break
        default:
          // catch的错误,暂时用这个...
          exportView({ content: t('76181'), duration: 3, type: 'error' })
          break
      }
    }

    return {
      ...toRefs(state),
      dayjs,
      onReset,
      onSearch,
      onChange,
      cacheChange,
      disabledDate,
      openChange,
      exportFunc,
      viewHistoricalVersion,
      didSelectHistoricalVersion,
      versionFilter,
      switchDefaultVersion,
      changeHistoricalVersion
    }
  }
})
