
import { formatCommasFixed } from '@/utils/math'
import { reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { ExportOutlined } from '@ant-design/icons-vue'

export default {
  components: { ExportOutlined },
  props: {
    pagination: Object,
    list: [],
    currency: {
      type: String,
      default: ''
    },
    exportLoading: Boolean
  },
  setup(_props: any, ctx: any) {
    const { t } = useI18n({})
    const state: {
      columns: any[]
    } = reactive({
      columns: [
        {
          title: t('75667'),
          width: 150,
          dataIndex: 'booking_ref_no',
          key: 'booking_ref_no',
          fixed: 'left'
        },
        {
          title: t('75668'),
          dataIndex: 'escrow_date',
          key: 'escrow_date'
        },
        {
          title: t('75669'),
          dataIndex: 'category_name',
          key: 'category_name',
          width: 150
        },
        {
          title: t('75670'),
          dataIndex: 'merchant_name',
          key: 'merchant_name',
          width: 180
        },
        {
          title: t('75671'),
          dataIndex: 'unit_count',
          key: 'unit_count',
          width: 100,
          align: 'right'
        },
        {
          dataIndex: 'total_price',
          key: 'total_price',
          align: 'right',
          width: 150
        },
        {
          title: t('75673'),
          dataIndex: 'redeemed_unit_count',
          key: 'redeemed_unit_count',
          align: 'right',
          width: 100
        },
        {
          dataIndex: 'redeemed_total_price',
          key: 'redeemed_total_price',
          align: 'right',
          width: 150
        },
        {
          title: t('75675'),
          dataIndex: 'refund_unit_count',
          key: 'refund_unit_count',
          width: 100,
          align: 'right'
        },
        {
          dataIndex: 'refund_total_price',
          align: 'right',
          key: 'refund_total_price',
          width: 150
        },
        {
          title: t('75677'),
          dataIndex: 'available_unit_count',
          key: 'available_unit_count',
          align: 'right',
          width: 100
        },
        {
          dataIndex: 'available_total_price',
          align: 'right',
          key: 'available_total_price',
          width: 150
        }
      ]
    })

    const onChange = (pageNumber: number, size: number) => {
      ctx.emit('onChange', pageNumber, size)
    }
    // 导出
    const onExport = () => {
      ctx.emit('exportFunc')
    }
    return {
      ...toRefs(state),
      formatCommasFixed,
      onChange,
      onExport,
      env: process.env.NODE_ENV
    }
  }
}
